<template>
  <file-pond
    ref="pond"
    credits=""
    label-idle="<i class='upload big text icon'></i>
      <span>Hier tippen, Dateien hier ablegen oder Foto aufnehmen</span>"
    label-file-processing-complete="Hochladen abgeschlossen"
    label-file-processing="Verarbeitung läuft"
    label-file-processing-error="Upload fehlgeschlagen"
    :allow-revert="false"
    :allow-multiple="true"
    :server="{ process: server, revert: null }"
    class="uploader-label"
    v-bind="$attrs"
  />
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import 'filepond/dist/filepond.min.css'

export function onBeforeUnload (event) {
  // === 5 PROCESSING_COMPLETE
  if (this.$refs.pond.getFiles().filter(file => file.status !== 5).length > 0) {
    event.preventDefault()
    event.returnValue = '' // Chrome
  }
}

export default {
  components: {
    FilePond: vueFilePond(FilePondPluginFileValidateType)
  },
  props: {
    server: {
      type: String,
      required: true
    }
  },
  created () {
    window.addEventListener('beforeunload', onBeforeUnload.bind(this))
  }
}
</script>

<style>
.uploader-label label span {
  height: 36px;
  display: inline-block;
  line-height: 100%;
  margin-left: 0.5rem;
}
</style>
