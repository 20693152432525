<script>
export default {
  model: {
    prop: 'externalState' // re-directs v-model off prop `value`
  },
  props: {
    binary: {
      // Whether to revert back to a binary checkbox, wins over indeterminate
      type: Boolean,
      default: false
    },
    checked: {
      // Whether to start out checked
      type: Boolean,
      default: false
    },
    disabled: {
      // Whether to start out disabled
      type: Boolean,
      default: false
    },
    id: {
      // HTML ID to use for the checkbox node of the component
      type: String,
      default: ''
    },
    indeterminate: {
      // Whether to start out indeterminate, wins over checked
      type: Boolean,
      default: false
    },
    name: {
      // Name to use during form submission; set to enable form submission
      type: String,
      default: ''
    },

    trueValue: {
      // Model value to use for checked state
      type: Boolean,
      default: true
    },
    falseValue: {
      // Model value to use for unchecked state
      type: Boolean,
      default: false
    },
    nullValue: {
      // Model value to use for indeterminate state
      type: String,
      default: null
    },

    value: {
      // Form submission value to use for checked state
      type: String,
      default: 'on'
    },
    valueIndeterminate: {
      // Form submission value to use for indeterminate state
      type: String,
      default: 'indeterminate'
    },

    externalState: {
      // Target for v-model when given (think this.$vnode.data.model.value)
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  data: function () {
    if (this.modelValue !== '') {
      return {}
    } else {
      return {
        internal_state: this.indeterminate
          ? this.nullValue
          : (this.checked
              ? this.trueValue
              : this.falseValue)
      }
    }
  },
  computed: {
    state: {
      get: function () {
        if (this.modelValue !== undefined && this.modelValue !== '') {
          return this.modelValue
        } else {
          return this.internal_state
        }
      },
      set: function (newValue) {
        if (this.modelValue !== undefined && this.modelValue !== '') {
          this.$emit('update:modelValue', newValue)
        } else {
          this.internal_state = newValue
        }
        this.apply_to_checkbox(newValue)
      }
    },

    // Value used for hidden field during form sumbission
    submission_value: function () {
      switch (this.state) {
        case this.trueValue:
          return this.value
        case this.nullValue:
          return this.valueIndeterminate
        case this.falseValue:
        default:
          return false // i.e. no submission as a plain checkbox would do
      }
    }
  },
  mounted: function () {
    this.apply_to_checkbox(this.state)
  },
  methods: {
    apply_to_checkbox: function (state) {
      const checkbox = this.$el.firstElementChild
      checkbox.checked = (state === this.trueValue)
      checkbox.indeterminate = (state === this.nullValue)
    },
    toggle: function () {
      if (this.binary) {
        this.state = !this.state
      } else {
        switch (this.state) {
          case this.falseValue:
            this.state = this.nullValue
            break
          case this.nullValue:
            this.state = this.trueValue
            break
          case this.trueValue:
            this.state = this.falseValue
            break
        }
      }
    }
  },
  template: `
    <div style="display: inline-block" ref="container">
      <input :id="id || false" ref="checkbox" :disabled="disabled"
        class="tristate-checkbox"
        type="checkbox"
        @click.stop="toggle"
        @keyup.space.prevent="toggle"
        />
      <input v-if="name && submission_value"
        type="hidden" :name="name"
        :value="submission_value"
        ref="hidden-input"
      />
    </div>
    `
}
</script>
