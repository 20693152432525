import axios from 'axios'

export function request (method, url, handler = function () {}, data = {}, options = {}) {
  return axios[method](url, data, options)
    .then(function (response) {
      return handler(response.data)
    })
    .catch(function (thrown) {
      if (thrown.code === 'ECONNABORTED') {
        return { data: null }
      } else {
        throw thrown
      }
    })
}
