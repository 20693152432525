<template>
  <section>
    <input
      type="hidden"
      name="contents"
      :value="contents"
    >
    <table class="aTable aList fullSize box padded">
      <thead class="thead">
        <th>Vordienstleistung</th>
        <th>Art</th>
        <th>Kosten</th>
      </thead>
      <tbody>
        <tr
          v-for="(preservice, field) in preservices"
          :key="field"
        >
          <td class="aTableCell label">
            Vordienstleistung {{ field + 1 }}
          </td>
          <td class="aTableCell">
            <input
              v-model="preservice.kind"
              type="text"
              :disabled="disabled"
              @change="serializeContent"
            >
          </td>
          <td class="aTableCell">
            <input
              v-model="preservice.costs"
              type="text"
              :disabled="disabled"
              @input="summarize"
              @change="serializeContent"
            >
          </td>
        </tr>
      </tbody>
      <tfoot class="table-total">
        <tr>
          <td
            class="aTableCell label"
            colspan="2"
          >
            Summe Accesstechnik
          </td>
          <td class="aTableCell">
            <input
              v-model="sum"
              type="text"
              disabled
            >
          </td>
        </tr>
      </tfoot>
    </table>
  </section>
</template>

<script>
import { formatMoney, parseMoney } from '@/js/field-calculator'
import { request } from '@/js/request'

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    initContents: {
      type: String,
      required: false,
      default: 'null'
    }
  },
  data: function () {
    return {
      preservices: [
        { kind: '', costs: '' },
        { kind: '', costs: '' },
        { kind: '', costs: '' },
        { kind: '', costs: '' }
      ],
      sum: '0,00',
      contents: '{}',
      initialized: false
    }
  },
  created: function () {
    const self = this

    return request('get', window.location.pathname + '.json', function (response) {
      if (!(response.fieldData instanceof Array) || response.fieldData.length === 0) return

      const valid = response.fieldData.reduce(function (acc, v) {
        return acc && v instanceof Object && 'kind' in v && 'costs' in v
      })

      if (valid && response.fieldData.length === 4) {
        self.preservices = response.fieldData
        self.serializeContent()
        self.summarize()
      }
    }).catch(function () {})
  },
  methods: {
    summarize: function () {
      const sum = this.preservices.reduce(function (acc, v) {
        return acc + parseMoney(v.costs)
      }, 0)
      this.sum = formatMoney(sum)
    },
    serializeContent: function () {
      if (!this.initialized && this.initContents !== 'null') {
        this.contents = JSON.parse(this.initContents)
        this.preservices = JSON.parse(this.initContents)
        this.initialized = true
      }
      this.contents = JSON.stringify(this.preservices)
    }
  }
}
</script>
