<template>
  <span>
    <a v-bind="{...attr, ...{class: 'pointer ' + ('class' in attr ? attr.class : '')}}">
      <FomanticIcon
        v-if="'name' in icon"
        v-bind="{...icon, ...{type: 'circular border'}}"
      />
      <span
        v-if="background"
        class="ICC dot inline"
        :style="{ backgroundColor: background }"
      />
      {{ background ? ' ' : '' }}
      <b>{{ label }}</b> {{ reference }}
    </a>
    {{ info }}
  </span>
</template>
<script>
import FomanticIcon from '@/components/_partials/Atom/FomanticIcon.ce.vue'

export default {
  tag: 'entity-link',
  components: { FomanticIcon },
  props: {
    className: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    reference: {
      type: String,
      default: ''
    },
    info: {
      type: String,
      default: ''
    },
    icon: {
      type: Object,
      default: () => {
        return {}
      }
    },
    attr: {
      type: Object,
      default: () => {
        return {}
      }
    },
    background: {
      type: String,
      default: () => null
    }
  }
}
</script>
