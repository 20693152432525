<template>
  <div
    id="showPrivileges"
    class="box show-privilege-box"
  >
    <div
      id="topicApp"
      class="list-topics"
    >
      <topic-app
        ref="topic-app"
        :user-id="userId"
        @topic-changed="getTopicPrivileges"
        @subtopic-changed="getSubtopicPrivileges"
      />
    </div>

    <h1 class="table-head">
      Effektive Zugriffsrechte
    </h1>

    <sui-table
      celled
      class="ui blue table"
    >
      <sui-table-header>
        <sui-table-row>
          <sui-table-header-cell>
            Nutzer
          </sui-table-header-cell>
          <sui-table-header-cell>
            lese - und Schreibzugriff
          </sui-table-header-cell>
          <sui-table-header-cell>
            nur mit Lese-Zugriff
          </sui-table-header-cell>
        </sui-table-row>
      </sui-table-header>

      <sui-table-body>
        <sui-table-row
          v-for="privilege in privileges"
          :key="privilege.username"
        >
          <sui-table-cell class="aTableCell">
            <p class="group">
              {{ privilege.username }}
            </p>
          </sui-table-cell>

          <sui-table-cell class="aTableCell">
            <p class="group">
              {{ privilege.readonly }}
            </p>
          </sui-table-cell>
          <sui-table-cell class="aTableCell">
            <p class="group">
              {{ privilege.readAndWrite }}
            </p>
          </sui-table-cell>
        </sui-table-row>
      </sui-table-body>
      <sui-table-footer v-if="!topic">
        <sui-table-row>
          <sui-table-header-cell colspan="4">
            <div class="center">
              <p>Wählen Sie bitte Thema oder Unterthema, um zugriffsrechte anzuzeigen.</p>
            </div>
          </sui-table-header-cell>
        </sui-table-row>
      </sui-table-footer>
    </sui-table>
  </div>
</template>

<script>
import { request } from '@/js/request'
import DynamicTopicForm from '@/components/History/DynamicTopicForm'
import {
  SuiTable,
  SuiTableFooter,
  SuiTableRow,
  SuiTableHeaderCell,
  SuiTableBody,
  SuiTableCell,
  SuiTableHeader
} from 'vue-fomantic-ui'

export default {
  components: {
    'topic-app': DynamicTopicForm,
    SuiTable,
    SuiTableFooter,
    SuiTableRow,
    SuiTableHeaderCell,
    SuiTableBody,
    SuiTableCell,
    SuiTableHeader
  },
  props: {
    userId: { type: Number, default: null }
  },
  data () {
    return {
      privileges: [],
      topic: null
    }
  },
  methods: {
    filterResults (data) {
      const result = {}

      for (const i in data) {
        const item = data[i]
        // use username property to check if username has already looped
        const toPush = (result[item.username]) ? result[item.username] : {}

        if (!toPush.readAndWrite && item.readonly) {
          toPush.readAndWrite = item.reason
        }

        if (!toPush.readonly && !item.readonly) {
          toPush.readonly = item.reason
        }

        if (!toPush.username) {
          toPush.username = item.username
        } else {
          if (item.readonly === true) {
            if (`${toPush.readAndWrite}`.indexOf(item.reason) === -1) {
              toPush.readAndWrite += `, ${item.reason}`
            }
          } else {
            if (`${toPush.readonly}`.indexOf(item.reason) === -1) {
              toPush.readonly += `, ${item.reason}`
            }
          }
        }

        result[item.username] = toPush
      }

      return result
    },
    getTopicPrivileges (topicId) {
      request('get', '/api/v1/privilege/topic/' + topicId,
        response => {
          this.privileges = this.filterResults(response)
        }
      )
      this.topic = topicId
    },
    getSubtopicPrivileges (subtopicId) {
      request('get', '/api/v1/privilege/subtopic/' + subtopicId,
        response => {
          this.privileges = this.filterResults(response)
        }
      )
    }
  }
}
</script>

<style>
.show-privilege-box {
  padding: 30px;
  overflow: visible;
}

.list-topics {
  width: 40%;
}

.table-head {
  padding-top: 30px;
}

.ui.blue.table {
  padding-top: 10px !important;
}

input.submitSearch {
  width: auto;
}

.ui.search .prompt {
  border-radius: 0;
}
</style>
