import { parseMoney, parseInt0 } from '@/js/field-calculator'

/**
 * @param {string} costsSum
 * @param {string} revenueSum
 * @param {string} interestRate
 * @returns {number}
 */
export function interest (costsSum, revenueSum, interestRate) {
  return (parseMoney(costsSum) - parseMoney(revenueSum)) * (parseMoney(interestRate) / 100)
}

/**
 * @param {string} monthlyRevenueSum
 * @returns {number}
 */
export function maintenanceCosts (monthlyRevenueSum) {
  return parseMoney(monthlyRevenueSum) / 10 * 12
}

/**
 * @param {string} interest
 * @param {string} maintenanceCosts
 * @param {string} monthlyProviderCost
 * @returns {number}
 */
export function monthlyCostsSum (interest, maintenanceCosts, monthlyProviderCost) {
  return (parseMoney(interest) + parseMoney(maintenanceCosts)) / 12 +
      parseMoney(monthlyProviderCost)
}

/**
 * @param {string} monthlyRevenueSum
 * @param {string} monthlyCostsSum
 * @param {string} term
 * @param {string} costsSum
 * @param {string} revenueSum
 * @returns {number}
 */
export function grossProfit (monthlyRevenueSum, monthlyCostsSum, term, costsSum, revenueSum) {
  const diff = parseMoney(monthlyRevenueSum) - parseMoney(monthlyCostsSum)
  return diff *
    parseInt0(term) -
    parseMoney(costsSum) +
    parseMoney(revenueSum)
}

/**
 * @param {string} capitalInvestment
 * @param {string} monthlyRevenueSum
 * @return {string}
 */
export function amortizationAfter (capitalInvestment, monthlyRevenueSum) {
  const monthlyRevenue = parseMoney(monthlyRevenueSum)

  if (monthlyRevenue === 0) {
    return ''
  } else {
    return Math.ceil(parseMoney(capitalInvestment) / monthlyRevenue).toString()
  }
}
