<template>
  <div id="topicTreeSelect">
    <label>Themen</label>
    <treeSelect
      id="treeselect"
      v-model="selectedTopics"
      :multiple="true"
      :options="topics"
      placeholder="Alle Themen anzeigen"
      clear-all-text="Auswahl zurücksetzen"
      clear-value-text="Thema entfernen"
      :flat="true"
      :searchable="false"
      name="themen_selected[]"
      @open="openMenu"
      @close="closeMenu"
      @input="submitFormIfEmptyAndClosed"
    />
  </div>
</template>
<script>
import Treeselect from '@m-kusumgar/vue3-treeselect'
import '@m-kusumgar/vue3-treeselect/dist/vue3-treeselect.css'
import { request } from '@/js/request'

export default {
  components: {
    treeSelect: Treeselect
  },
  props: {
    initSelectedTopics: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      topics: [],
      formOpen: false,
      selectedTopics: []
    }
  },
  created () {
    this.selectedTopics = this.initSelectedTopics
    request('get', '/api/v1/topic/list/tree', response => {
      this.topics = response
    })
  },
  methods: {
    openMenu: function () {
      this.formOpen = true
    },
    closeMenu: function () {
      this.formOpen = false
      document.getElementById('treeSelectForm').submit()
    },
    submitFormIfEmptyAndClosed: function (value) {
      // this method handles the "clear all" case (if the menu is closed)
      if (value.length === 0 && !this.formOpen) {
        setTimeout(function () {
          document.getElementById('treeSelectForm').submit()
        }, 100)
      }
    }
  }
}
</script>

<style>
.vue-treeselect__control {
  border-radius: 0 !important;
}
</style>
