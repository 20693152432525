<template>
  <div id="flash-message">
    <transition name="fade">
      <SuiMessage
        v-if="visibility"
        dismissable
        :color="color"
        :content="message"
        @dismiss="handleDismiss"
      />
    </transition>
  </div>
</template>

<script>
import { SuiMessage } from 'vue-fomantic-ui'
export default {
  components: {
    SuiMessage
  },
  props: {
    color: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visibility: this.visible
    }
  },
  mounted () {
    this.$root.$on('reinitialize-flash-message', () => {
      // reinitialize if called again from parent component
      this.visibility = true
    })
  },
  methods: {
    handleDismiss () {
      this.visibility = false
    }
  }
}
</script>
