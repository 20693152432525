<template>
  <i :class="classNames" />
</template>
<script>
export default {
  tag: 'fomantic-icon',
  props: {
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    classNames () {
      const additional = []

      if (this.background.length > 0) {
        additional.push('c-background--' + this.background)
      }

      if (this.type.length > 0) {
        additional.push(this.type)
      }

      if (this.color.length > 0) {
        additional.push(this.color)
      }

      return 'icon ' + this.name + ' ' + additional.join(' ')
    }
  }
}
</script>
