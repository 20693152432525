<template>
  <a
    :class="'ui primary labeled icon button ' + extraClasses"
    href="#"
    @click.prevent="openWindow"
  >
    <i
      v-if="icon !== ''"
      :class="'icon ' + icon"
    />
    {{ text }}
  </a>
</template>

<script>
import { fenster } from '@/js/project'

export default {
  tag: 'window-button',
  props: {
    title: { type: String, default: 'Titel' },
    text: { type: String, default: '' },
    link: { type: String, default: '' },
    icon: { type: String, default: '' },
    classes: { type: String, default: '' }
  },
  data () {
    return {
      extraClasses: this.classes
    }
  },
  created () {
    if (this.icon !== '') {
      this.extraClasses += 'fullSize'
    }
  },
  methods: {
    openWindow () {
      fenster(this.link, this.title, 1040, 1040)
    }
  }
}
</script>
