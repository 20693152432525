<template>
  <RouterView />
</template>

<script>
import { RouterView } from 'vue-router'

export default {
  components: {
    RouterView
  },
  provide () {
    return {
      initConfirmText: this.initConfirmText,
      initCallback: this.initCallback
    }
  },
  props: {
    initConfirmText: { type: String, default: '' },
    initCallback: { type: String, default: '' }
  },
  data () {
    return {}
  }
}
</script>
