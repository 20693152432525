import $ from 'jquery'
import { defaultTo, drop, last, startsWith } from 'ramda'

export function parseMoney (n) {
  const parsed = parseFloat(n.replace(/\./g, '').replace(',', '.'))
  return Number.isNaN(parsed) ? 0 : parsed
}

export function formatMoney (n) {
  return n.toFixed(2).replace(/\./, ',')
}

export function multiplyFields (x, y) {
  return formatMoney(parseMoney(x) * parseMoney(y))
}

export function parseInt0 (value) {
  const result = parseInt(value)

  return isNaN(result) ? 0 : result
}

export function summarize (fieldsData, field, group) {
  let sum = 0

  $.each(group.fields, function (fieldKey) {
    if (field === group.fields[fieldKey]) {
      const fieldData = fieldsData[fieldKey]
      field.product = multiplyFields(fieldData.quantity, fieldData.factor)
    }

    if ('product' in group.fields[fieldKey]) {
      sum += parseMoney(group.fields[fieldKey].product)
    }
  })
  group.sum = formatMoney(sum)
}

function getCustomFieldIndex (fieldKey) {
  return parseInt(drop('custom_'.length, fieldKey))
}

export function findNextIndex (fieldGroup) {
  const fieldKeys = Object.keys(fieldGroup.fields)
  const lastFieldKey = last(fieldKeys)

  return lastFieldKey && startsWith('custom_', lastFieldKey)
    ? getCustomFieldIndex(lastFieldKey) + 1
    : 1
}

export function emptyCustomField (group) {
  return {
    label: '',
    company: '',
    quantity: '',
    kind: '',
    factor: '',
    group: group.id
  }
}

export function initializeLines (response) {
  this.fieldGroups = response.fieldGroups
  const responseData = defaultTo({}, response.fieldData)
  const customFields = Object.keys(responseData)
    .filter(startsWith('custom_'))
    .sort((key1, key2) => getCustomFieldIndex(key1) - getCustomFieldIndex(key2))

  for (const group of this.fieldGroups) {
    group.sum = '0,00'

    for (const field in group.fields) {
      group.fields[field].product = '0,00'

      if (field in responseData) {
        this.fieldData[field] = Object.assign(
          response.fieldData[field],
          { kind: group.fields[field].kind, group: group.id }
        )
        this.summarize(group.fields[field], group)
      } else {
        this.fieldData[field] = {
          quantity: '',
          factor: '',
          company: '',
          group: group.id,
          kind: group.fields[field].kind
        }
      }
    }
    customFields.filter(customField => responseData[customField].group === group.id)
      .forEach(customField => {
        this.fieldData[customField] =
          Object.assign(emptyCustomField(group), responseData[customField])
        group.fields[customField] = {
          product: '0,00'
        }
        this.summarize(group.fields[customField], group)
      })
  }
}
